@tailwind components;
@tailwind base;
@layer base {
  img {
    @apply inline-block;
  }
}

@tailwind utilities;

* {
  font-family: 'Inter', sans-serif;
}

/* https://www.w3schools.com/howto/howto_css_hide_arrow_number.asp */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
  border: none;
}

body {
  margin: 0;
  padding: 0;
  color: #212121;
}

.ruleGroup {
  background-color: #f3f4f6 !important;
  border: 1px solid #d2d5da;
  border-radius: 8px;
}

.ruleGroup-combinators {
  background-color: #ffffff !important;
  border-radius: 8px;
  border: 1px solid #d2d5da;
  padding: 8px 8px 8px 12px;
  width: 75px;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
}

.ruleGroup-addRule {
  background-color: #ffffff !important;
  border-radius: 8px;
  border: 1px solid #d2d5da;
  padding: 8px 8px 8px 12px;
  width: 75px;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
}

.ruleGroup-addGroup {
  background-color: #ffffff !important;
  border-radius: 8px;
  border: 1px solid #d2d5da;
  padding: 8px 8px 8px 12px;
  width: 75px;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
}

.rule-fields,
.rule-operators,
.rule-value {
  background-color: #ffffff !important;
  border-radius: 8px;
  border: 1px solid #d2d5da;
  text-overflow: ellipsis;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
}

.rdw-dropdown-wrapper,
.rdw-option-wrapper {
  background: transparent;
  border: none;
}

.rdw-option-active {
  border: 1px solid rgb(218, 215, 215);
  border-radius: 4px;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #f3f4f6;
  margin: 4px 0px 4px 0;
}

::-webkit-scrollbar-thumb {
  background: #d2d5da;
  border-radius: 12px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

input[type='range'] {
  -webkit-appearance: none;
  appearance: none;
  background: #e0e7ff;
  cursor: pointer;
  height: 4px;
  border-radius: 999px;
}

input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  background-color: #6366f1;
  height: 20px;
  width: 20px;
  border-radius: 999px;
}

/* Thumb: Firefox */
input[type='range']::-moz-range-thumb {
  background-color: #6366f1;
  height: 20px;
  width: 20px;
  border-radius: 999px;
  border: transparent;
}

.react-hint__content {
  font-size: 12px;
}

.pulse {
  animation: pulse-animation 2s infinite;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0);
  }

  100% {
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.2);
  }
}

.master {
  color: black;
  float: right;
}

@media (max-width: 900px) {
  .master {
    display: none;
  }
}
